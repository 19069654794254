import React from "react";
// Customizable Area Start
import { styled, ThemeProvider } from "@material-ui/core/styles";
import MedicalInformationController, {
    configJSON,
    IMedicalConditions,
    IMedicalInformations,
    IOrganDonation,
    Props,
} from "./MedicalInformationController.web";
import { 
  Box, 
  Grid, 
  Typography, 
  Button,
  FormLabel, 
  RadioGroup, 
  FormControlLabel,
  Checkbox,
  FormGroup, 
} from "@material-ui/core";
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import { Field, FieldArray, Form, Formik, FormikErrors, FormikTouched, FormikValues } from "formik";
import CustomSelect from "../../../components/src/CustomSelect";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { DialogWrapper, StyledRadio, theme } from "./LegalInformation.web";
import CustomInput from "../../../components/src/CustomInput";
import MediaUpload from "../../../components/src/MediaUpload.web";
import AdditionalInformationSidebar from "./AdditionalInfoSidebar.web";
import GenericModal from "../../../components/src/GenericModal";

const conditionType = [
    { value: 1, name: "Hereditary" },
    { value: 2, name: "Non- Hereditary" },
  ];

const levelOfCondition = [
    { value: 1, name: "Critical" },
    { value: 2, name: "Stable" },
    { value: 3, name: "Fair" },
  ];

const bloodGroup = [
  { value: 1, name: "O+ (O Positive)" },
  { value: 2, name: "O- (O Negative)" },
  { value: 3, name: "A+ (A Positive)" },
  { value: 4, name: "A- (A Negative)" },
  { value: 5, name: "B+ (B Positive)" },
  { value: 6, name: "B- (B Negative)" },
  { value: 7, name: "AB+ (AB Positive)" },
  { value: 8, name: "AB- (AB Negative)" },
];
// Customizable Area End

export default class MedicalInformation extends MedicalInformationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMedicalConditionForm = (
    values: FormikValues, 
    errors: FormikErrors<IMedicalInformations>, 
    touched: FormikTouched<IMedicalInformations>, 
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    return(
      <FieldArray name="medical_condition" data-test-id="fieldArray">
        {({ remove, push }) => (
          values.medical_condition.map((medicalCondition: IMedicalConditions, index: number) => {
            return(
              <Grid item style={{ margin: "24px 0" }} className="formSectionBackground">
                <Grid container spacing={2}>
                  <Grid item xs={12} className="medicalConditionHeaderWrapper">
                    <Typography data-test-id="medicalConditionFormTitle" variant="h6">{configJSON.medicalConditionFormTitle}</Typography>
                    {index === 0 && (
                    <Button 
                      className="addMoreConditionsBtn"
                      data-test-id = "addMoreConditionsBtn"
                      onClick={() => push({
                        condition_type: "",
                        condition_name: "",
                        level: "",
                        description: "",
                      })}
                    >
                      <AddCircleOutlineIcon className="circleOutlineIcon" />
                      {configJSON.addMoreConditionsBtnText}
                    </Button>
                    )}
                    {index !== 0 && (
                    <Button 
                      data-test-id="removeConditionBtn"
                      className="addMoreConditionsBtn"
                      onClick={() => remove(index)}>
                      <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                      {configJSON.removeConditionBtnText}
                    </Button>)}
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">
                        {configJSON.conditionTypeLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="conditionType"
                      className="selectField"
                      name="conditionType"
                      as={CustomSelect}
                      placeholder="Select condition type"
                      options={conditionType}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`medical_condition.${index}.condition_type`, e.target.value)
                      }}
                      value={medicalCondition.condition_type}
                      error={touched.medical_condition?.[index]?.condition_type &&
                        (errors.medical_condition?.[index] as FormikErrors<IMedicalConditions>)?.condition_type
                      }
                      errorMsg={(errors.medical_condition?.[index] as FormikErrors<IMedicalConditions>)?.condition_type}
                    />
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">
                        {configJSON.conditionNameLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="conditionName"
                      className="inputField"
                      name="conditionName"
                      as={CustomInput}
                      size="small"
                      placeholder="Enter condition name"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`medical_condition.${index}.condition_name`, e.target.value)
                      }}
                      value={medicalCondition.condition_name}
                      error={touched.medical_condition?.[index]?.condition_name &&
                        (errors.medical_condition?.[index] as FormikErrors<IMedicalConditions>)?.condition_name
                      }
                      errorMsg={(errors.medical_condition?.[index] as FormikErrors<IMedicalConditions>)?.condition_name}
                    />
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">
                        {configJSON.levelOfConditionLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="levelOfCondition"
                      className="selectField"
                      name="levelOfCondition"
                      as={CustomSelect}
                      placeholder="Level of Condition"
                      options={levelOfCondition}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`medical_condition.${index}.level`, e.target.value)
                      }}
                      value={medicalCondition.level}
                      error={touched.medical_condition?.[index]?.level &&
                        (errors.medical_condition?.[index] as FormikErrors<IMedicalConditions>)?.level
                      }
                      errorMsg={(errors.medical_condition?.[index] as FormikErrors<IMedicalConditions>)?.level}
                    />
                  </Grid>
                  <Grid item lg={12}>
                    <FormLabel component="label">{configJSON.descriptionFieldLabel}</FormLabel>
                    <Field
                      data-test-id="medicalConditionDescription"
                      name="medicalConditionDescription"
                      size="medium"
                      multiline
                      style={{ minHeight: "84px !important" }}
                      as={CustomInput}
                      placeholder="Details about condition"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(`medical_condition.${index}.description`, e.target.value)
                      }
                      value={medicalCondition.description}
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        )}
      </FieldArray>
  )};

  renderOrganDonationDetails = (
    values: FormikValues, 
    errors: FormikErrors<IMedicalInformations>, 
    touched: FormikTouched<IMedicalInformations>, 
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    const organsList = ['All', 'Lungs', 'Pancreas', 'Liver', 'Heart', 'Other'];

    return(
      <Grid item xs={12} style={{ margin: "24px 0" }} className="formSectionBackground">
        <Box mb={2}>
          <Typography className="textMargin" variant="h6" data-test-id="organDonationDetailsTitle">
            {configJSON.organDonationDetailsFormTitle}
          </Typography>
          <Typography variant="subtitle2" className="textMargin">
            {configJSON.organDonationDetailsFormDescription}
          </Typography>
        </Box>
      <FormLabel component="label">{configJSON.idNumberFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
        <Field
          data-test-id="idNumber"
          name="idNumber"
          size="small"
          className="inputField"
          as={CustomInput}
          placeholder="Enter ID number"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue(`organ_donation.id_number`, e.target.value)
          }
          value={values.organ_donation.id_number}
          error={touched.organ_donation?.id_number && errors.organ_donation?.id_number
          }
          errorMsg={errors.organ_donation?.id_number}
          />
      <Typography variant="body2" className="textMargin">
        {configJSON.organsCheckBoxListLabel}
      </Typography>
      <FormGroup row>
              {organsList.map((organ) => (
                <Box key={organ}>
                   <Checkbox
                      data-test-id="organCheckboxList"
                      checked={values.organ_donation.organs.includes(organ)}
                      color="primary"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFieldValue('organ_donation.organs', [...values.organ_donation.organs, organ])
                        } else {
                          setFieldValue(
                            'organ_donation.organs',
                            values.organ_donation.organs.filter((item: string) => item !== organ)
                          );
                        }
                      }}
                    />
                    <FormLabel component="label" className="organCheckboxLabel">
                      {organ}
                    </FormLabel>
                </Box>
              ))}
            </FormGroup>
            { values.organ_donation.organs.includes("Other") && (
                  <FieldArray name="organ_donation.organ_name" data-test-id="fieldArrayOrganName">
                  {({ remove, push }) => (
                    values.organ_donation.organ_name.map((organName: string, index: number) => {
                      return(
                        <Box className="organNameFieldWrapper" key={index}>
                          <FormLabel component="label">{configJSON.organNameFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                          <Field
                            data-test-id="organName"
                            name="organName"
                            size="small"
                            className="inputField"
                            as={CustomInput}
                            placeholder="Enter organ name"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setFieldValue(`organ_donation.organ_name.${index}`, e.target.value)
                            }
                            value={organName}
                            error={(touched.organ_donation as FormikErrors<IOrganDonation>)?.organ_name?.[index] &&
                              (errors.organ_donation as FormikErrors<IOrganDonation>)?.organ_name?.[index]
                            }
                            errorMsg={(errors.organ_donation as FormikErrors<IOrganDonation>)?.organ_name?.[index]}
                            />
                          {index === 0 && (
                            <Button
                              className="addMoreConditionsBtn"
                              data-test-id="addMoreOrganNameBtn"
                              onClick={() => push("")}
                            >
                              <AddCircleOutlineIcon className="circleOutlineIcon" />
                                {configJSON.addMoreBtnText}
                            </Button>
                          )}
                          {index !== 0 && (
                            <Button
                              data-test-id="removeOrganNameBtn"
                              className="addMoreConditionsBtn"
                              onClick={() => remove(index)}>
                              <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                {configJSON.removeBtnText}
                            </Button>)}
                        </Box>
                      )}
                    ))}
                  </FieldArray>
              )}
      </Grid>
  )};

  renderBloodGroupForm = (
    values: FormikValues, 
    errors: FormikErrors<IMedicalInformations>, 
    touched: FormikTouched<IMedicalInformations>, 
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
      const max_medical_documents = this.state.subscriptionData?.features ? this.state.subscriptionData.features.max_medical_documents : 3;
    return(
      <Grid item style={{ margin: "24px 0" }} className="formSectionBackground">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel component="label">
              {configJSON.bloodGroupFieldLabel}<span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Field
              data-test-id="bloodGroup"
              className="selectField"
              name="bloodGroup"
              as={CustomSelect}
              placeholder="Select blood group"
              options={bloodGroup}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(`blood_type.blood_group`, e.target.value)
              }}
              value={values.blood_type.blood_group}
              error={touched.blood_type?.blood_group && errors.blood_type?.blood_group
              }
              errorMsg={errors.blood_type?.blood_group}
            />
          </Grid>
          <Grid item lg={12}>
            <MediaUpload
              data-test-id="mediaUpload"
              files={this.state.files}
              onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue)}
              maxFiles={max_medical_documents}
            />
          </Grid>
        </Grid>
      </Grid>
    )};
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { 
      medicalInformations,
      delegateInCharge,
      showMedicalConditionForm,
      showOrganDonationDetails,
      showBloodGroupForm,
      openDialog,
    } = this.state;
    return (
      <>
        <ThemeProvider theme={theme}>
          <Wrapper>
            <WebHeader navigation={this.props.navigation} progress={18} />
            <Box className="container">
              <Box className="sidebarWrapper">
                <AdditionalInformationSidebar navigation={this.props.navigation} />
              </Box>
              <Box className="medicalInfoWrapper">
                <Box className="formWrapper">
                  <Formik
                    initialValues={medicalInformations}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                      this.handleMedicalInformationsFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="medicalInformationForm"
                  >
                    {({ values, errors, touched, setFieldValue }) => (
                      <Form>
                        <Box className="formWrapper">
                          <Box className="pageTitle">
                            <Typography data-test-id="pageTitle" variant="h3" className="titleText">
                              {configJSON.medicalInfoPageTitle}
                            </Typography>
                            <Box className="checkboxWrapper">
                              <Checkbox
                                color="primary"
                                checked={values.medical_information.include_in_final_pdf}
                                onChange={(e) => {
                                  setFieldValue('medical_information.include_in_final_pdf', e.target.checked)
                                }}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                style={{ color: "#9176C4" }}
                              />
                              <FormLabel component="label" className="pdfLabel">
                                {configJSON.pdfLabel}
                              </FormLabel>
                            </Box>
                          </Box>
                          <Box>
                            <Grid container spacing={2}>
                              <Grid item>
                                <FormLabel component="label">
                                  {configJSON.DelegateInChargeLabel}<span style={{ color: "red" }}>*</span>
                                </FormLabel>
                                <Field
                                  data-test-id="delegateInCharge"
                                  name="delegateInCharge"
                                  className="selectField delegateSelectField"
                                  as={CustomSelect}
                                  placeholder="Select Delegate"
                                  options={delegateInCharge}
                                  setValue={true}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setFieldValue("medical_information.delegate_id", e.target.value)
                                  }
                                  value={values.medical_information.delegate_id}
                                  error={
                                    touched.medical_information?.delegate_id &&
                                    errors.medical_information?.delegate_id
                                  }
                                  errorMsg={errors.medical_information?.delegate_id}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormLabel component="label" className="formRadioLabel">
                                  {configJSON.haveMedicalConditionQuestion}
                                </FormLabel>
                                <RadioGroup
                                  className="radioGroup"
                                  data-test-id="haveMedicalCondition"
                                  aria-label="have_medical_condition"
                                  name="have_medical_condition"
                                  value={values.medical_information.have_medical_condition}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue("medical_information.have_medical_condition", e.target.value);
                                    this.setState({ showMedicalConditionForm: !this.state.showMedicalConditionForm })
                                  }
                                  }
                                >
                                  <FormControlLabel
                                    className="radioLabel"
                                    value="1"
                                    control={<StyledRadio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    className="radioLabel"
                                    value="0"
                                    control={<StyledRadio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </Grid>
                              {showMedicalConditionForm && (
                                this.renderMedicalConditionForm(values, errors, touched, setFieldValue)
                              )}
                              <Grid item xs={12}>
                                <FormLabel component="label" className="formRadioLabel">
                                  {configJSON.haveRegisteredOrganDonationQuestion}
                                </FormLabel>
                                <RadioGroup
                                  className="radioGroup"
                                  data-test-id="haveRegisteredOrganDonation"
                                  aria-label="have_registered_organ_donation"
                                  name="have_registered_organ_donation"
                                  value={values.medical_information.have_registered_organ_donation}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue("medical_information.have_registered_organ_donation", e.target.value);
                                    this.setState({ showOrganDonationDetails: !showOrganDonationDetails });
                                  }
                                  }
                                >
                                  <FormControlLabel
                                    className="radioLabel"
                                    value="0"
                                    control={<StyledRadio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    className="radioLabel"
                                    value="1"
                                    control={<StyledRadio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </Grid>
                              {showOrganDonationDetails && (
                                this.renderOrganDonationDetails(values, errors, touched, setFieldValue)
                              )}
                              <Grid item xs={12}>
                                <FormLabel component="label" className="formRadioLabel">
                                  {configJSON.haveYouGiveBloodQuestion}
                                </FormLabel>
                                <RadioGroup
                                  className="radioGroup"
                                  data-test-id="haveYouGiveBlood"
                                  aria-label="have_you_give_blood"
                                  name="have_you_give_blood"
                                  value={values.medical_information.have_you_give_blood}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue("medical_information.have_you_give_blood", e.target.value);
                                  }
                                  }
                                >
                                  <FormControlLabel
                                    className="radioLabel"
                                    value="1"
                                    control={<StyledRadio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    className="radioLabel"
                                    value="0"
                                    control={<StyledRadio />}
                                    label="No"
                                  />
                                  <FormControlLabel
                                    className="radioLabel"
                                    value="2"
                                    control={<StyledRadio />}
                                    label="Sometimes"
                                  />
                                </RadioGroup>
                              </Grid>
                              <Grid item xs={12}>
                                <FormLabel component="label" className="formRadioLabel">
                                  {configJSON.haveYouKnowBloodTypeQuestion}
                                </FormLabel>
                                <RadioGroup
                                  className="radioGroup"
                                  data-test-id="haveYouKnowBloodType"
                                  aria-label="have_you_know_blood_type"
                                  name="customized-radios"
                                  value={values.medical_information.have_you_know_blood_type}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue("medical_information.have_you_know_blood_type", e.target.value);
                                    this.setState({ showBloodGroupForm: !showBloodGroupForm });
                                  }
                                  }
                                >
                                  <FormControlLabel
                                    className="radioLabel"
                                    value="1"
                                    control={<StyledRadio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    className="radioLabel"
                                    value="0"
                                    control={<StyledRadio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </Grid>
                              {showBloodGroupForm && (
                                this.renderBloodGroupForm(values, errors, touched, setFieldValue)
                              )}
                            </Grid>
                          </Box>
                          <Box className="btnWrapper">
                            <Button
                              data-test-id="skipForNow"
                              className="btnSize"
                              variant="outlined"
                              onClick={() => this.handleNavigation("CustomForm")}
                            >
                              {configJSON.skipForNowBtnText}
                            </Button>
                            <Button
                              data-test-id="saveAndNextBtn"
                              type="submit"
                              className="btnSize"
                              variant="contained"
                            >
                              {configJSON.saveAndNext}
                            </Button>
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Box>
            <WebFooter handleNavigation={this.handleNavigation} />
          </Wrapper>
        </ThemeProvider>
        <GenericModal open={openDialog} data-test-id="information-added-modal" onClose={this.handleCloseDialog}>
          <ThemeProvider theme={theme}>
            <DialogWrapper>
              <Box className="dialogContentWrapper">
                <Box className="dialogDescriptionWrapper">
                  <Typography 
                    data-test-id="emailSentModalTitle"
                    align="center" 
                    variant="h1" 
                  >
                    {configJSON.infoAddedDialogTitleText}
                  </Typography>
                  <Typography align="center" variant="h2">
                    Your medical information has been added successfully to your data. Please select<span className="nextSpan"> Next</span>  to add more information.
                  </Typography>
                </Box>
                <Box className="dialogBtnsWrapper">
                  <Button
                    data-test-id="skipForNowDialogBtn"
                    variant="outlined"
                    className="outlinedBtn"
                    onClick={
                      () => this.handleNavigation("CustomForm")
                    }
                  >
                    {configJSON.skipForNowBtnText}
                  </Button>
                  <Button
                    data-test-id="nextSectionBtn"
                    variant="contained"
                    className="containedBtn"
                    onClick={() => this.handleNavigation("InvestmentsAndFunds")}
                  >
                    {configJSON.nextSectionBtnText}
                  </Button>
                </Box>
              </Box>
            </DialogWrapper>
          </ThemeProvider>
        </GenericModal>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
  "& .container": {
    padding: "50px 72px",
    display: "flex",
    gap: "72px",
  },
  "& .pageTitle": {
    display: "flex",
    justifyContent: "space-between",
    margin: "16px 0",
  },
  "& .checkboxWrapper": {
    display: 'flex',
    alignItems: 'center',
  },
  "& .pdfLabel": {
    color: "#0F172A",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  "& .formWrapper": {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  "& .textMargin": {
    margin: "8px 0px",
  },
  "& .inputField": {
    borderRadius: "8px",
    height: "48px",
    width: "300px",
  },
  "& .delegateSelectField": {
    width: "275px !important",
  },
  "& .selectField": {
    margin: "5px 0px",
    borderRadius: "8px",
    width: "300px",
    height: "48px",
    display: "block",
  },
  "& .radioGroup": {
    display: 'flex',
    marginLeft: "16px",
    gap: "35px",
    flexDirection: 'row',
  },
  "& .radioLabel": {
    fontFamily: "Lato",
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 400,
    color: '#1E293B',
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#9176C4',
    },
  },
  "& .formSectionBackground": {
    borderRadius: "8px",
    padding: "24px",
    backgroundColor: "rgba(206, 195, 229, 0.1)",
  },
  "& .btnWrapper": {
    display: "flex",
    gap: "8px",
    justifyContent: "flex-end",
  },
  "& .btnSize": {
    height: "48px",
    width: "158px",
  },
  "& .medicalConditionHeaderWrapper": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .addMoreConditionsBtn": {
    color: "#9176C4",
    textTransform: "none",
    fontSize: "14px",
    fontFamily: "Lato",
    fontWeight: 700,
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    '&:hover': {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  "& .circleOutlineIcon": {
    marginRight: "8px",
    fontSize: "16px",
  },
  [theme.breakpoints.down(740)]: {
    "& .container": {
        padding: "28px 16px",
    },
  },
}));
// Customizable Area End
