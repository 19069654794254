import React from "react";
// Customizable Area Start
import {
  FormLabel,
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepIcon,
  Typography,
  Avatar,
  Button,
  IconButton,
  Badge,
  Menu,
  Fade,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
  Tooltip,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MyLocationTwoToneIcon from '@material-ui/icons/MyLocationTwoTone';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import LeftPanel from "../../../components/src/LeftPanel";
import { 
  Field, 
  Form, 
  Formik, 
  FormikErrors, 
  FormikTouched, 
  FormikValues 
} from "formik";
import CustomInput from "../../../components/src/CustomInput";
import CustomSelect from "../../../components/src/CustomSelect";
import GenericModal from "../../../components/src/GenericModal";
import { rightTickImg } from "../../../blocks/forgot-password/src/assets";
import CustomDatePicker from "../../../components/src/CustomDatePicker";
import { countries } from "./CustomisableUserProfilesController";
import DelegateMembersController, {
  Props,
  configJSON,
  IDelegateMember,
} from "./DelegateMembersController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontFamily: "Lato",
        fontSize: "24px",
        fontWeight: 700,
        color: "#0F172A",
        lineHeight: "32px",
      },
      h5: {
        fontFamily: "Quattrocento",
        fontSize: "20px",
        fontWeight: 700,
        color: "#0F172A",
        lineHeight: "28px",
      },
      h6: {
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 500,
        color: "#9176C4",
        lineHeight: "18px",
      },
      subtitle1: {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 400,
        color: "#64748B",
        lineHeight: "22px",
      },
      body1: {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 700,
        color: "#9176C4",
        lineHeight: "26px",
      },
      body2: {
        fontFamily: "Lato",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 600,
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 400,
        color: "#334155",
        lineHeight: "22px",
      }
    },
    MuiStepLabel: {
      root: {
        label: {
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 600,
          lineHeight: "24px",
        },
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "8px",
        minHeight: "48px",
        "& [class*=MuiOutlinedInput-root].Mui-focused": {
          borderColor: "#94A3B8",
        },
        "& .Mui-focused": {
          "& [class*=MuiOutlinedInput-notchedOutline]": {
            borderColor: "#94A3B8 !important",
          }
        },
      },
      input: {
        "&::placeholder": {
          fontFamily: "Lato !important",
          color: "#94A3B8",
          fontWeight: 400,
          opacity: 1,
        },
        "&::focused": {
          borderColor: "#94A3B8 !important",
        },
      },
    },
    MuiInputBase: {
      root: {
        borderRadius: "8px",
        minHeight: "48px",
        "& .Mui-focused": {
          "& [class*=MuiOutlinedInput-notchedOutline]": {
            borderColor: "#94A3B8 !important",
          }
        },
      },
    },
    MuiButton: {
      contained: {
        width: "248px",
        height: "48px",
        fontFamily: "Lato",
        fontSize: '16px',
        fontWeight: 600,
        color: "#FFFFFF",
        backgroundColor: "#A190C2",
        borderRadius: "100px",
        textTransform: "none",
      },
      outlined: {
        width: "248px",
        height: "48px",
        fontFamily: "Lato",
        fontSize: '16px',
        fontWeight: 600,
        color: "#9176C4",
        borderRadius: "100px",
        textTransform: "none",
      },
    },
    MuiDialog: {
      paper: {
        maxWidth: "418px !important",
      },
    },
    MuiTooltip: {
      popper: {
        backgroundColor: "none",
      }
    },
    MuiCardHeader: {
      avatar: {
        marginRight: "8px",
      },
      action: {
        alignSelf: "flex-center",
      }
    }
  }
});
// Customizable Area End

export default class DelegateMembers extends DelegateMembersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDelegateMemberCard = (delegateMember: IDelegateMember) => {
    const CardWrapper = styled('div')(({ theme }) => ({
      "& .card-root": {
        width: "450px",
        maxHeight: "224px",
        borderRadius: "8px",
        margin: "24px 0px",
      },
      "& .card-header": {
        padding: "16px 16px 12px 16px",
        maxHeight: "72px",
        gap: "8px",
        backgroundColor: '#CEC3E5',
      },
      "& .avatar": {
        width: "44px",
        height: "44px",
      },
      "& .cardHeaderTitle": {
        fontFamily: "Lato",
        fontSize: "10px",
        fontWeight: 700,
        color: "#9176C4",
      },
      "& .cardHeadersubtitle": {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 500,
        color: "#0F172A",
      },
      "& .icon": {
        verticalAlign: 'middle',
        marginRight: theme.spacing(1),
      },
      "& .iconButton": {
        marginLeft: 'auto',
      },
      "& .content": {
        paddingTop: "12px",
        width: "392px",
        height: "124px",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      },
      "& .typography": {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 400,
        color: "#334155",
        display: "flex",
        lineHeight: "22px",
        marginLeft: "8px",
        gap: "3px",
      },
      "& [class=*MuiCardHeader-avatar-]": {
        marginRight: "8px",
      },
    }));

    return (
      <ThemeProvider theme={theme}>
        <CardWrapper>
          <Card className="card-root">
            <CardHeader
              data-test-id="cardHeader"
              avatar={
                <Avatar
                  alt="Delegate Member"
                  src={delegateMember.photo}
                  className="avatar"
                />
              }
              action={
                <IconButton className="iconButton" onClick={() => this.handleEditDelegateMember(delegateMember)}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.5 18.334H2.5C2.15833 18.334 1.875 18.0507 1.875 17.709C1.875 17.3673 2.15833 17.084 2.5 17.084H17.5C17.8417 17.084 18.125 17.3673 18.125 17.709C18.125 18.0507 17.8417 18.334 17.5 18.334Z" fill="#9176C4" />
                    <path d="M15.8505 2.90005C14.2338 1.28338 12.6505 1.24172 10.9921 2.90005L9.9838 3.90838C9.90047 3.99172 9.86714 4.12505 9.90047 4.24172C10.5338 6.45005 12.3005 8.21672 14.5088 8.85005C14.5421 8.85838 14.5755 8.86672 14.6088 8.86672C14.7005 8.86672 14.7838 8.83338 14.8505 8.76672L15.8505 7.75838C16.6755 6.94172 17.0755 6.15005 17.0755 5.35005C17.0838 4.52505 16.6838 3.72505 15.8505 2.90005Z" fill="#9176C4" />
                    <path d="M13.0079 9.60768C12.7663 9.49101 12.5329 9.37435 12.3079 9.24102C12.1246 9.13268 11.9496 9.01602 11.7746 8.89102C11.6329 8.79935 11.4663 8.66602 11.3079 8.53268C11.2913 8.52435 11.2329 8.47435 11.1663 8.40768C10.8913 8.17435 10.5829 7.87435 10.3079 7.54102C10.2829 7.52435 10.2413 7.46602 10.1829 7.39102C10.0996 7.29102 9.95795 7.12435 9.83295 6.93268C9.73295 6.80768 9.61628 6.62435 9.50795 6.44102C9.37461 6.21602 9.25795 5.99102 9.14128 5.75768C8.98832 5.4299 8.5581 5.33253 8.30233 5.5883L3.61628 10.2743C3.50795 10.3827 3.40795 10.591 3.38295 10.7327L2.93295 13.9243C2.84961 14.491 3.00795 15.0243 3.35795 15.3827C3.65795 15.6743 4.07461 15.8327 4.52461 15.8327C4.62461 15.8327 4.72461 15.8243 4.82461 15.8077L8.02461 15.3577C8.17461 15.3327 8.38295 15.2327 8.48295 15.1243L13.1767 10.4306C13.4274 10.1799 13.3332 9.74863 13.0079 9.60768Z" fill="#9176C4" />
                  </svg>
                </IconButton>
              }
              title={<Typography className="cardHeaderTitle" data-test-id="cardHeaderTitle">
                {configJSON.delegateUser}{" "}{delegateMember.delegate_key === "delegate_1" ? "1" : "2"}
              </Typography>}
              subheader={
                <Typography className="cardHeadersubtitle">
                  {delegateMember.first_name + " " + delegateMember.last_name}
                </Typography>
              }
              className="card-header"
            />
            <CardContent className="content">
              <Typography className="typography">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.333 13.6673H4.66634C2.66634 13.6673 1.33301 12.6673 1.33301 10.334V5.66732C1.33301 3.33398 2.66634 2.33398 4.66634 2.33398H11.333C13.333 2.33398 14.6663 3.33398 14.6663 5.66732V10.334C14.6663 12.6673 13.333 13.6673 11.333 13.6673Z" stroke="#475569" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11.3337 6L9.24699 7.66667C8.56032 8.21333 7.43366 8.21333 6.74699 7.66667L4.66699 6" stroke="#475569" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </svg>&nbsp;{delegateMember.email}
              </Typography>
              <Typography className="typography">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.6463 12.2207C14.6463 12.4607 14.593 12.7073 14.4797 12.9473C14.3663 13.1873 14.2197 13.414 14.0263 13.6273C13.6997 13.9873 13.3397 14.2473 12.933 14.414C12.533 14.5807 12.0997 14.6673 11.633 14.6673C10.953 14.6673 10.2263 14.5073 9.45967 14.1807C8.69301 13.854 7.92634 13.414 7.16634 12.8607C6.39967 12.3007 5.67301 11.6807 4.97967 10.994C4.29301 10.3007 3.67301 9.57398 3.11967 8.81398C2.57301 8.05398 2.13301 7.29398 1.81301 6.54065C1.49301 5.78065 1.33301 5.05398 1.33301 4.36065C1.33301 3.90732 1.41301 3.47398 1.57301 3.07398C1.73301 2.66732 1.98634 2.29398 2.33967 1.96065C2.76634 1.54065 3.23301 1.33398 3.72634 1.33398C3.91301 1.33398 4.09967 1.37398 4.26634 1.45398C4.43967 1.53398 4.59301 1.65398 4.71301 1.82732L6.25967 4.00732C6.37967 4.17398 6.46634 4.32732 6.52634 4.47398C6.58634 4.61398 6.61967 4.75398 6.61967 4.88065C6.61967 5.04065 6.57301 5.20065 6.47967 5.35398C6.39301 5.50732 6.26634 5.66732 6.10634 5.82732L5.59967 6.35398C5.52634 6.42732 5.49301 6.51398 5.49301 6.62065C5.49301 6.67398 5.49967 6.72065 5.51301 6.77398C5.53301 6.82732 5.55301 6.86732 5.56634 6.90732C5.68634 7.12732 5.89301 7.41398 6.18634 7.76065C6.48634 8.10732 6.80634 8.46065 7.15301 8.81398C7.51301 9.16732 7.85967 9.49398 8.21301 9.79398C8.55967 10.0873 8.84634 10.2873 9.07301 10.4073C9.10634 10.4207 9.14634 10.4407 9.19301 10.4607C9.24634 10.4807 9.29967 10.4873 9.35967 10.4873C9.47301 10.4873 9.55967 10.4473 9.63301 10.374L10.1397 9.87398C10.3063 9.70732 10.4663 9.58065 10.6197 9.50065C10.773 9.40732 10.9263 9.36065 11.093 9.36065C11.2197 9.36065 11.353 9.38732 11.4997 9.44732C11.6463 9.50732 11.7997 9.59398 11.9663 9.70732L14.173 11.274C14.3463 11.394 14.4663 11.534 14.5397 11.7007C14.6063 11.8673 14.6463 12.034 14.6463 12.2207Z" stroke="#475569" stroke-width="1.2" stroke-miterlimit="10" />
                </svg>&nbsp;+{delegateMember.full_phone_number || "Not Provided"}
              </Typography>
              <Typography className="typography">
                <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.99992 12.953C9.14867 12.953 10.0799 12.0217 10.0799 10.873C10.0799 9.72422 9.14867 8.79297 7.99992 8.79297C6.85117 8.79297 5.91992 9.72422 5.91992 10.873C5.91992 12.0217 6.85117 12.953 7.99992 12.953Z" stroke="#475569" stroke-width="1.2" />
                  <path d="M2.41379 9.66065C3.72712 3.88732 12.2805 3.89399 13.5871 9.66732C14.3538 13.054 12.2471 15.9207 10.4005 17.694C9.06046 18.9873 6.94046 18.9873 5.59379 17.694C3.75379 15.9207 1.64712 13.0473 2.41379 9.66065Z" stroke="#475569" stroke-width="1.2" />
                </svg>&nbsp;{delegateMember.address_line_1}, {delegateMember.address_line_2}. {delegateMember.city}, {delegateMember.country} - {delegateMember.post_code}
              </Typography>
            </CardContent>
          </Card>
        </CardWrapper>
      </ThemeProvider>
    )
  }

  renderInfoIcon = () => {
    return (
      <LightTooltip title={
        <>
          <Typography style={{
            fontFamily: "Lato",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "18px",
            color: "#000000",
          }}>
            {configJSON.securityPhraseInfo}
          </Typography>
        </>
      }
      >
        <InfoOutlinedIcon className="infoIcon"/>
      </LightTooltip>
    );
  };

  renderImageField = (values: FormikValues, setFieldValue: {
    (field: string,
      value: any,
      shouldValidate?: boolean | undefined): void;
    (arg0: string, arg1: string): void;
  }) => {
    return (
      <Box>
        <input
          data-test-id="profile-image"
          id="profilePicInput"
          type="file"
          name="profile_pic"
          accept="image/*"
          onChange={(event) =>
            this.handleProfileImg(event, setFieldValue)
          }
          style={{ display: 'none' }}
        />
        <label htmlFor="profilePicInput">
          <IconButton component="span" style={{ paddingLeft: '0' }}>
            {this.state.profileImage || values.photo ? (
              <Badge
                className="profilePicBadge"
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                badgeContent={
                  <>
                    <div onClick={(e: React.MouseEvent<HTMLInputElement>) =>
                      this.handleProfilePicEditMenu(e)
                    }>
                      <IconButton
                        style={{ padding: "0" }}
                      >
                        <svg fill="none" height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <rect fill="#9176C4" width="24" height="24" rx="12" />
                          <path d="M17.25 17.834H6.75C6.51083 17.834 6.3125 17.6357 6.3125 17.3965C6.3125 17.1573 6.51083 16.959 6.75 16.959H17.25C17.4892 16.959 17.6875 17.1573 17.6875 17.3965C17.6875 17.6357 17.4892 17.834 17.25 17.834Z" fill="white" />
                          <path d="M16.095 7.02964C14.9633 5.89798 13.855 5.86881 12.6942 7.02964L11.9883 7.73548C11.93 7.79381 11.9067 7.88714 11.93 7.96881C12.3733 9.51464 13.61 10.7513 15.1558 11.1946C15.1792 11.2005 15.2025 11.2063 15.2258 11.2063C15.29 11.2063 15.3483 11.183 15.395 11.1363L16.095 10.4305C16.6725 9.85881 16.9525 9.30464 16.9525 8.74464C16.9583 8.16714 16.6783 7.60714 16.095 7.02964Z" fill="white" />
                          <path d="M14.1058 11.726C13.9367 11.6443 13.7733 11.5626 13.6158 11.4693C13.4875 11.3935 13.365 11.3118 13.2425 11.2243C13.1433 11.1601 13.0267 11.0668 12.9158 10.9735C12.9042 10.9676 12.8633 10.9326 12.8167 10.886C12.6242 10.7226 12.4083 10.5126 12.2158 10.2793C12.1983 10.2676 12.1692 10.2268 12.1283 10.1743C12.07 10.1043 11.9708 9.98763 11.8833 9.85346C11.8133 9.76596 11.7317 9.63763 11.6558 9.5093C11.5625 9.3518 11.4808 9.1943 11.3992 9.03096C11.2921 8.80152 10.9909 8.73335 10.8119 8.9124L7.53167 12.1926C7.45583 12.2685 7.38583 12.4143 7.36833 12.5135L7.05333 14.7476C6.995 15.1443 7.10583 15.5176 7.35083 15.7685C7.56083 15.9726 7.8525 16.0835 8.1675 16.0835C8.2375 16.0835 8.3075 16.0776 8.3775 16.066L10.6175 15.751C10.7225 15.7335 10.8683 15.6635 10.9383 15.5876L14.224 12.302C14.3994 12.1265 14.3335 11.8246 14.1058 11.726Z" fill="white" />
                        </svg>
                      </IconButton>
                    </div>
                    <Menu
                      id="fade-menu"
                      anchorEl={this.state.anchorEl}
                      open={Boolean(this.state.anchorEl)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                      }}
                      onClose={this.handleProfilePicEditMenuClose}
                      getContentAnchorEl={null}
                      TransitionComponent={Fade}
                      keepMounted
                    >
                      <MenuItem>
                        <input
                          data-test-id="change-image"
                          id="changeProfilePicInput"
                          type="file"
                          name="profile_pic_change"
                          accept="image/*"
                          onChange={(event) =>
                            this.handleProfileImg(event, setFieldValue)
                          }
                          style={{ display: 'none' }}
                        />
                        <label style={{ display: "flex" }} htmlFor="changeProfilePicInput">
                          <Box>
                            <svg fill="none" width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9.94501 2.70041L3.78751 9.21791C3.55501 9.46541 3.33001 9.95291 3.28501 10.2904L3.00751 12.7204C2.91001 13.5979 3.54001 14.1979 4.41001 14.0479L6.82501 13.6354C7.16251 13.5754 7.63501 13.3279 7.86751 13.0729L14.025 6.55541C15.09 5.43041 15.57 4.14791 13.9125 2.58041C12.2625 1.02791 11.01 1.57541 9.94501 2.70041Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M8.91748 3.78711C9.23998 5.85711 10.92 7.43961 13.005 7.64961" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M2.25 16.5H15.75" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </Box>
                          &nbsp;{configJSON.changePicture}
                        </label>
                      </MenuItem>
                      <MenuItem onClick={() => this.handleRemoveProfilePic(setFieldValue)}>
                        <Box>
                          <svg fill="none" width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.75 4.48438C13.2525 4.23687 10.74 4.10938 8.235 4.10938C6.75 4.10938 5.265 4.18438 3.78 4.33438L2.25 4.48438" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6.375 3.7275L6.54 2.745C6.66 2.0325 6.75 1.5 8.0175 1.5H9.9825C11.25 1.5 11.3475 2.0625 11.46 2.7525L11.625 3.7275" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14.1375 6.85547L13.65 14.408C13.5675 15.5855 13.5 16.5005 11.4075 16.5005H6.59255C4.50005 16.5005 4.43255 15.5855 4.35005 14.408L3.86255 6.85547" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.74756 12.375H10.2451" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.125 9.375H10.875" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </Box>
                        &nbsp;{configJSON.removePicture}
                      </MenuItem>
                    </Menu>
                  </>
                }
              >
                <Avatar
                  className="profilePicAvatar"
                  style={{
                    margin: '5px 0 10px 0'
                  }}
                  alt={values.first_name || ''}
                  src={(this.state.profileImage as string) || values.photo}
                />
              </Badge>
            ) : (
              <svg fill="none" width="62" height="62" viewBox="0 0 62 62" xmlns="http://www.w3.org/2000/svg">
                <rect fill="#F8FFF0" x="0.5" y="0.5" width="61" height="61" rx="30.5" />
                <rect stroke="#ACCC86" x="0.5" y="0.5" width="61" height="61" rx="30.5" />
                <path
                  fill="#ACCC86"
                  d="M33.12 24L34.95 26H39V38H23V26H27.05L28.88 24H33.12ZM34 22H28L26.17 24H23C21.9 24 21 24.9 21 26V38C21 39.1 21.9 40 23 40H39C40.1 40 41 39.1 41 38V26C41 24.9 40.1 24 39 24H35.83L34 22ZM31 29C32.65 29 34 30.35 34 32C34 33.65 32.65 35 31 35C29.35 35 28 33.65 28 32C28 30.35 29.35 29 31 29ZM31 27C28.24 27 26 29.24 26 32C26 34.76 28.24 37 31 37C33.76 37 36 34.76 36 32C36 29.24 33.76 27 31 27Z"
                />
              </svg>
            )}
          </IconButton>
        </label>
      </Box>
    );
  };

  renderDelegateMemberAddressForm = (
    values: FormikValues, 
    errors: FormikErrors<IDelegateMember>, 
    touched: FormikTouched<IDelegateMember>, 
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    return(
      <>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ alignItems: "flex-start" }} className="addressTitle">
            <ErrorOutlineIcon className="ErrorOutlineIcon" />{configJSON.delegateMemberAddressDescription}
          </Typography>
        </Grid>
        <Grid item lg={6}>
          <FormLabel component="label">
            {configJSON.addressLine1}<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Field
            data-test-id="address_line_1"
            name="address_line_1"
            className="inputField"
            size="small"
            startIcon={styledMyLocationTwoToneIcon}
            as={CustomInput}
            placeholder="House/Flat no."
            value={values.address_line_1}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue("address_line_1", e.target.value)
            }
            errorMsg={errors.address_line_1}
            error={touched.address_line_1 && errors.address_line_1}
            disabled={this.state.delegateMember.address_line_1 && !this.state.editMode}
          />
        </Grid>
        <Grid item lg={6}>
          <FormLabel component="label">
            {configJSON.addressLine2}<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Field
            name="address_line_2"
            data-test-id="address_line_2"
            className="inputField"
            size="small"
            as={CustomInput}
            placeholder="Street and Locality"
            value={values.address_line_2}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue("address_line_2", e.target.value)
            }
            error={touched.address_line_2 && errors.address_line_2}
            errorMsg={errors.address_line_2}
            disabled={this.state.delegateMember.address_line_2 && !this.state.editMode}
          />
        </Grid>
        <Grid item lg={6}>
          <FormLabel component="label">
            {configJSON.landmarkLabel}
          </FormLabel>
          <Field
            data-test-id="landmark"
            name="landmark"
            className="inputField"
            size="small"
            as={CustomInput}
            placeholder="Landmark"
            value={values.landmark}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue("landmark", e.target.value)
            }
            disabled={this.state.delegateMember.landmark && !this.state.editMode}
          />
        </Grid>
        <Grid item lg={6}>
          <FormLabel component="label">
            {configJSON.townCityLabel}<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Field
            data-test-id="city"
            name="city"
            className="inputField"
            size="small"
            as={CustomInput}
            placeholder="City"
            value={values.city}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue("city", e.target.value)
            }
            errorMsg={errors.city}
            error={touched.city && errors.city}
            disabled={this.state.delegateMember.city && !this.state.editMode}
          />
        </Grid>
        <Grid item lg={6}>
          <FormLabel component="label">
            {configJSON.countryLabel}<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Field
            data-test-id="country"
            name="country"
            className="selectField"
            as={CustomSelect}
            placeholder="Country"
            options={countries}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue("country", e.target.value)
            }}
            value={values.country}
            errorMsg={errors.country}
            error={touched.country && errors.country}
            disabled={this.state.delegateMember.country && !this.state.editMode}
          />
        </Grid>
        <Grid item lg={6}>
          <FormLabel component="label">
            {configJSON.postCodeLabel}<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Field
            data-test-id="postcode"
            name="postcode"
            className="inputField"
            size="small"
            as={CustomInput}
            placeholder="Post Code"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue("post_code", e.target.value)
            }
            value={values.post_code}
            errorMsg={errors.post_code}
            error={touched.post_code && errors.post_code}
            disabled={this.state.delegateMember.post_code && !this.state.editMode}
          />
        </Grid>
      </>
    );
  };

  renderDelegateMemberForm = () => {
    return (
      <>
        <Box>
          <Typography
            data-test-id="addDelegateMemberTitle"
            variant="body1"
            style={{
              color: "#0F172A"
            }}>
            {configJSON.addDelegateMemberTitle}&nbsp;
            {this.state.delegateKey === "delegate_1" ? "1" : "2"}</Typography>
        </Box>
        <Box mt={3} mr={7}>
          <Formik
            initialValues={this.state.delegateMember}
            validationSchema={this.delegateMemberValidationSchema(this.state.phoneNumberLength)}
            onSubmit={(values) => {
              this.handleDelegateFormSubmit(values)
            }}
            enableReinitialize={true}
            data-test-id="delegatemembersForm"
          >
            {({ values, errors, touched, setFieldValue }) => (
              <Form>
                {this.renderImageField(values, setFieldValue)}
                <Grid container spacing={2}>
                  <Grid item lg={6}>
                    <FormLabel component="label">
                      {configJSON.firstNameLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="first_name"
                      name="first_name"
                      className="inputField"
                      size="small"
                      as={CustomInput}
                      placeholder="Enter your first name"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("first_name", e.target.value)
                      }
                      value={values.first_name}
                      error={touched.first_name && errors.first_name}
                      errorMsg={errors.first_name}
                      disabled = {this.state.delegateMember.first_name && !this.state.editMode}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <FormLabel component="label">
                      {configJSON.middleNameLabel}
                    </FormLabel>
                    <Field
                      data-test-id="middle_name"
                      name="middle_name"
                      className="inputField"
                      size="small"
                      as={CustomInput}
                      placeholder="Enter your middle name"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("middle_name", e.target.value)
                      }
                      value={values.middle_name}
                      disabled = {this.state.delegateMember.middle_name && !this.state.editMode}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <FormLabel component="label">
                      {configJSON.lastNameLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="last_name"
                      name="last_name"
                      className="inputField"
                      size="small"
                      as={CustomInput}
                      placeholder="Enter your last name"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("last_name", e.target.value)
                      }
                      value={values.last_name}
                      error={touched.last_name && errors.last_name}
                      errorMsg={errors.last_name}
                      disabled = {this.state.delegateMember.last_name && !this.state.editMode}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <FormLabel component="label">
                      {configJSON.nationality}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="nationality"
                      className="selectField"
                      name="nationality"
                      placeholder="Select your Nationality"
                      as={CustomSelect}
                      options={this.state.nationalities}
                      value={values.nationality}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("nationality", e.target.value)
                      }
                      error={touched.nationality && errors.nationality}
                      errorMsg={errors.nationality}
                      disabled = {this.state.delegateMember.nationality && !this.state.editMode}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <FormLabel component="label">
                      {configJSON.genderLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="gender"
                      name="gender"
                      className="selectField"
                      as={CustomSelect}
                      placeholder="Select gender"
                      options={[
                        { value: 1, name: "Male" },
                        { value: 2, name: "Female" },
                        { value: 3, name: "Other" },
                      ]}
                      value={values.gender}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("gender", e.target.value)
                      }
                      error={touched.gender && errors.gender}
                      errorMsg={errors.gender}
                      disabled = {this.state.delegateMember.gender && !this.state.editMode}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <FormLabel component="label">
                      {configJSON.emailIdLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="email"
                      className="inputField"
                      name="email"
                      size="small"
                      placeholder="example@gmailcom"
                      as={CustomInput}
                      value={values.email}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("email", e.target.value)
                      }
                      error={touched.email && errors.email}
                      errorMsg={errors.email}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    { this.renderPhoneInput(values, errors, touched, setFieldValue) }
                  </Grid>
                  <Grid item lg={6}>
                    <FormLabel component="label">
                      {configJSON.dateOfBirthLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="date_of_birth"
                      name="date_of_birth"
                      className="inputField"
                      size="small"
                      as={CustomDatePicker}
                      placeholder="DD/MM/YYYY"
                      endIcon={
                        this.calenderIcon
                      }
                      onChange={(date: Date) =>
                        setFieldValue("date_of_birth", date.toLocaleDateString("en-GB"))
                      }
                      ageRestriction={16}
                      value={values.date_of_birth}
                      error={touched.date_of_birth && errors.date_of_birth}
                      errorMsg={errors.date_of_birth}
                    />
                  </Grid>
                  { this.renderDelegateMemberAddressForm(values, errors, touched, setFieldValue) }
                  <Grid item lg={6}>
                    <FormLabel component="label">
                      {configJSON.securityPhraseLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="security_phrase"
                      name="security_phrase"
                      className="inputField"
                      size="small"
                      as={CustomInput}
                      placeholder="Security phrase"
                      endIcon={this.renderInfoIcon}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("security_phrase", e.target.value)
                      }
                      value={values.security_phrase}
                      error={touched.security_phrase && errors.security_phrase}
                      errorMsg={errors.security_phrase}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="btnWrapper">
                      <Button
                        data-test-id="nextBtn"
                        variant="contained"
                        style={{ width: "240px", height: "48px" }}
                        type="submit"
                      >
                        {configJSON.nextBtnText}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </>
    )
  }

  renderPhoneInput = (
    values: FormikValues, 
    errors: FormikErrors<IDelegateMember>, 
    touched: FormikTouched<IDelegateMember>, 
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    return(
      <>
        <FormLabel component="label">
          {configJSON.phoneNumber}<span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Box>
          <PhoneInput
            data-test-id="full_phone_number"
            country={'gb'}
            value={values.full_phone_number}
            onChange={(value, country) => 
              this.handlePhoneInputChange(value, country, setFieldValue)
            }
            inputStyle={{
              width: '300px',
              height: '48px',
              border: (touched.phone_number && errors.phone_number) ? '1px solid red' : '1px solid #e0e0e0',
              borderRadius: '8px',
              padding: '10px 40px',
            }}
            containerStyle={{
              margin: '5px 0',
              width: '300px',
              height: '48px',
            }}
            inputProps={{
              name: 'phone',
              autoFocus: false,
              required: true,
            }}
            buttonStyle={{
              border: (touched.phone_number && errors.phone_number) ? '1px solid red' : '1px solid #e0e0e0',
              borderTopLeftRadius: '8px',
              borderBottomLeftRadius: '8px',
            }}
          />
          {touched.phone_number && errors.phone_number &&
            <FormHelperText error className="errorMsg">
              <InfoOutlinedIcon className="errorIcon" />
              <span>{errors.phone_number}</span>
            </FormHelperText>
          }
        </Box>
      </>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { 
      activeStep,
      delegateMember1,
      delegateMember2,
      editMode,
      showMemberCard1,
      showMemberCard2,
      openAllSetDialog,
    } = this.state;
    return (
      <>
        <Wrapper>
          <LeftPanel>
            <ThemeProvider theme={theme}>
              <Box pl={6} py={9} className="rightPanelWrapper">
                <Box className={`${activeStep === "completed" && !editMode ? "rightPanelContentWrapper" : ""}`}>
                  <Box className="stepperWrapper">
                    <Stepper className="stepper" nonLinear activeStep={1}>
                      <Step key="Profile">
                        <StepLabel data-test-id="stepLabel" className="stepLabel" StepIconComponent={(props) => (<StepIcon icon={
                          <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect fill="#F8FAFC" x="3" y="3.5" width="26" height="26" rx="13" />
                            <rect stroke="#ACCC86" x="3" y="3.5" width="26" height="26" rx="13" stroke-width="6" />
                            <path d="M13.4497 19.7863L10.6883 16.8429C10.5396 16.684 10.3378 16.5948 10.1273 16.5948C9.91679 16.5948 9.71494 16.684 9.56626 16.8429C9.25591 17.1737 9.25591 17.7081 9.56626 18.0389L12.8926 21.5846C13.203 21.9154 13.7043 21.9154 14.0147 21.5846L22.4341 12.6101C22.7444 12.2793 22.7444 11.7449 22.4341 11.4141C22.2854 11.2553 22.0835 11.166 21.873 11.166C21.6625 11.166 21.4607 11.2553 21.312 11.4141L13.4497 19.7863Z" fill="#ACCC86" />
                          </svg>
                        }></StepIcon>)}><Typography variant="body2">{configJSON.createProfileStep1}</Typography></StepLabel>
                      </Step>
                      <Step key="Delegate">
                        <StepLabel data-test-id="stepLabel" className="stepLabel" StepIconComponent={(props) => (<StepIcon icon={
                          activeStep === "completed" ?
                            (
                              <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect fill="#F8FAFC" x="3" y="3.5" width="26" height="26" rx="13" />
                                <rect stroke="#ACCC86" x="3" y="3.5" width="26" height="26" rx="13" stroke-width="6" />
                                <path d="M13.4497 19.7863L10.6883 16.8429C10.5396 16.684 10.3378 16.5948 10.1273 16.5948C9.91679 16.5948 9.71494 16.684 9.56626 16.8429C9.25591 17.1737 9.25591 17.7081 9.56626 18.0389L12.8926 21.5846C13.203 21.9154 13.7043 21.9154 14.0147 21.5846L22.4341 12.6101C22.7444 12.2793 22.7444 11.7449 22.4341 11.4141C22.2854 11.2553 22.0835 11.166 21.873 11.166C21.6625 11.166 21.4607 11.2553 21.312 11.4141L13.4497 19.7863Z" fill="#ACCC86" />
                              </svg>
                            ) :
                            (<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="3" y="3.5" width="26" height="26" rx="13" fill="#F8FAFC" />
                              <rect x="3" y="3.5" width="26" height="26" rx="13" stroke="#CEC3E3" stroke-width="6" />
                              <path d="M13.4497 19.7863L10.6883 16.8429C10.5396 16.684 10.3378 16.5948 10.1273 16.5948C9.91679 16.5948 9.71494 16.684 9.56626 16.8429C9.25591 17.1737 9.25591 17.7081 9.56626 18.0389L12.8926 21.5846C13.203 21.9154 13.7043 21.9154 14.0147 21.5846L22.4341 12.6101C22.7444 12.2793 22.7444 11.7449 22.4341 11.4141C22.2854 11.2553 22.0835 11.166 21.873 11.166C21.6625 11.166 21.4607 11.2553 21.312 11.4141L13.4497 19.7863Z" fill="#CEC3E3" />
                            </svg>)
                        }></StepIcon>)}><Typography variant="body2">{configJSON.createProfileStep2}</Typography></StepLabel>
                      </Step>
                    </Stepper>
                  </Box>
                  <Box>
                    <Box className="addDelegateMemberFormTitleWrapper">
                      <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <Typography data-test-id="delegateMemberFormTitle" variant="h5">
                          {configJSON.addDelegateMemberTitle}
                        </Typography>
                        <LightTooltip title={
                          <Typography style={{
                            fontSize: "12px",
                            fontWeight: 400,
                            fontFamily: "Lato",
                            color: "#000000",
                            lineHeight: "18px",
                            maxWidth: "188px",
                            padding: "11px 12px",
                          }}>
                            {configJSON.delegateMemberInfo}
                          </Typography>
                        }
                        >
                          <ErrorOutlineIcon className="ErrorOutlineIcon" />
                        </LightTooltip>
                      </Box>
                      <Typography variant="subtitle1">{configJSON.delegateMemberSubtitle}</Typography>
                    </Box>
                    <Box className="alignCenter1">
                      {showMemberCard1 && (
                        this.renderDelegateMemberCard(delegateMember1)
                      )}
                      {showMemberCard2 && (
                        this.renderDelegateMemberCard(delegateMember2)
                      )}
                    </Box>
                    {(delegateMember1.id === 0 || delegateMember2.id === 0 || editMode) &&
                      this.renderDelegateMemberForm()
                    }
                    {(showMemberCard1 && showMemberCard2 && !editMode) && (
                      <Box className="confirmBtnWrapper">
                        <Button
                          style={{ width: "342px", height: "48px" }}
                          data-test-id="confirmDetailsBtn"
                          className="containedBtn"
                          variant="contained"
                          onClick={() => this.handleConfirmDetailsBtnClick()}>
                          {configJSON.confirmDetailsBtnText}
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </ThemeProvider>
          </LeftPanel>
        </Wrapper>
        <Box className="dialogWrapper">
          <GenericModal open={openAllSetDialog} dataTest="all-set" onClose={this.handleCloseAllSetDialog}>
            <ThemeProvider theme={theme}>
              <DialogWrapper>
                <Box className="alignCenter">
                  <img alt="success" src={rightTickImg} className="rightTickImg" />
                  <Typography align="center" variant="h1" data-test-id="youAreAllSet">
                    {configJSON.youAreAllSet}
                  </Typography>
                  <Typography align="center" variant="h6" style={{ color: "#64748B", fontWeight: 400 }}>
                    {configJSON.youAreAllSetDescription}
                  </Typography>
                </Box>
                <Box className="btnWrapper">
                  <Button
                    fullWidth
                    style={{ width: "100%" }}
                    className="containedBtn"
                    variant="contained"
                    onClick={() => this.handleContinueBtnClick()}>
                    {configJSON.continueBtnText}
                  </Button>
                </Box>
              </DialogWrapper>
            </ThemeProvider>
          </GenericModal>
        </Box>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled('div')(({ theme }) => ({
  "& .rightPanelWrapper": {
    overflowY: "auto",
    maxHeight: "100vh",
    boxSizing: "border-box",
  },
  "& .rightPanelContentWrapper": {
    maxWidth: "462px",
    margin: "0 auto",
  },
  "& .container": {
    minHeight: '100vh',
  },
  "& .stepperWrapper": {
    width: "415px",
  },
  "& .stepper": {
    fontFamily: "Lato !important",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    padding: "0",
  },
  "& .stepLabel": {
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& .createProfileFormTitleWrapper": {
    margin: "16px 0px",
    width: "327px",
    height: "59",
  },
  "& .profilePicAvatar": {
    width: "62px",
    height: "62px",
  },
  "& .ErrorOutlineIcon": {
    "&:hover": {
      fill: "#9176C4",
    },
    color: "#9176C4",
  },
  "& .profilePic": {
    marginBottom: "16px",
  },
  "& .inputField": {
    height: "48px",
    width: "300px",
    borderRadius: "8px",
  },
  "& .calenderIcon": {
    cursor: "grab",
  },
  "& .selectField": {
    borderRadius: "8px",
    margin: "5px 0px",
    display: "block",
    height: "48px",
    width: "300px",
  },
  "& .addressTitle": {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    maxWidth: "570px",
  },
  "& .btnWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    gap: "8px",
  },
  "& .confirmBtnWrapper": {
    display: "flex",
    justifyContent: "center",
  },
  "& .delegateMemberWrapper": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .delegateMemberLabel": {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#1E293B",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  "& .addDelegateMemberFormTitleWrapper": {
    maxWidth: "605px",
    margin: "16px 0px",
  },
  "& .alignCenter": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .infoIcon": {
    fill: "#A190C2",
    "&:hover": {
      backgroundColor: "#A190C2",
      fill: "#FFF",
      borderRadius: "100px",
    },
  },
  //PhoneInput CSS override
  "& .react-tel-input .selected-flag:hover": {
    backgroundColor: "unset",
  },
  "& .react-tel-input .flag-dropdown.open .selected-flag": {
    backgroundColor: "unset",
  },

  "& .errorIcon": {
    marginRight: "2px",
    marginBottom: "3px",
    width: "16px", 
    height: "16px",
  },
  "& .errorMsg": {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    fontSize: "12px",
    color: "#DC2626",
  },
}));

const DialogWrapper = styled('div')(({ theme }) => ({
  "& .dialogWrapper": {
    maxWidth: "475px",
    maxHeight: "368px",
  },
  "& .btnWrapper": {
    display: "flex",
    justifyContent: "center",
    marginTop: "32px",
    gap: "8px",
  },
  "& .alignCenter": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
  },
  "& .emailSpan": {
    fontColor: "Black",
    fontWeight: 700,
  },
  "& .containedBtn": {
    width: "192px",
    heigh: "56px",
    borderRadius: "100px",
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Lato",
    backgroundColor: "#9176C4",
    padding: "16px",
    textTransform: "none",
  },
  "& .outlinedBtn": {
    heigh: "56px",
    width: "192px",
    color: "#9176C4",
    borderRadius: "100px",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Lato",
    padding: "16px",
    textTransform: "none",
  },
  "& .infoPaper": {
    width: "293px",
    height: "196px",
    padding: "10px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
  "& .rightTickImg": {
    height: "72px",
    width: "72px",
  },
  [theme.breakpoints.down('xs')]: {
    "& .btnWrapper": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }
  }
}));

const styledMyLocationTwoToneIcon =
  styled(MyLocationTwoToneIcon)({
    fill: "#A190C2",
  });

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
))(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
  fontFamily: "Lato",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
}));

// Customizable Area End
